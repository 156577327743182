import React, { useContext, useEffect } from 'react'
import { ArticleContext } from '../../Context/AppContext'
import parse from 'html-react-parser';
import { baseUrl } from '../../services/Services';
import im1 from '../../assets/guide/im1.jpeg';
import im2 from '../../assets/guide/im2.jpeg';
import im3 from '../../assets/guide/im3.jpeg';
import im4 from '../../assets/guide/im4.jpeg';
import im5 from '../../assets/guide/im5.jpeg';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Helmet } from "react-helmet";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { A11y, Navigation, Pagination } from 'swiper';
import { ClientRouter } from '../../utils/Route/Route';
import { Link } from 'react-router-dom';

export const DetailGuidVComponent = () => {
    const article: any = useContext(ArticleContext)

    // useEffect(() => {

    //     const metaTitle = document.createElement('meta');
    //     metaTitle.setAttribute('property', 'og:title');
    //     metaTitle.content = 'Réservez des hébergements à Madagascar';

    //     // Créer et ajouter la balise meta pour l'image
    //     const metaImage = document.createElement('meta');
    //     metaImage.setAttribute('property', 'og:image');
    //     metaImage.content = 'https://api.haodymadagascar.com/images/IMG-202494u6pp6egq';
    
    //     // Créer et ajouter la balise meta pour la largeur de l'image
    //     const metaImageWidth = document.createElement('meta');
    //     metaImageWidth.setAttribute('property', 'og:image:width');
    //     metaImageWidth.content = '1200';
    
    //     // Créer et ajouter la balise meta pour la hauteur de l'image
    //     const metaImageHeight = document.createElement('meta');
    //     metaImageHeight.setAttribute('property', 'og:image:height');
    //     metaImageHeight.content = '630';
    
    //     // Créer et ajouter la balise meta pour la description
    //     const metaDescription = document.createElement('meta');
    //     metaDescription.setAttribute('property', 'og:description');
    //     metaDescription.content = 'Réservez des hébergements partout à Madagascar';
    
    //     // Trouver l'élément head
    //     const head = document.head;
    
    //     // Trouver la première balise link avec rel="manifest", rel="stylesheet", ou rel="apple-touch-icon"
    //     const firstLinkElement = head.querySelector('link[rel="manifest"], link[rel="stylesheet"], link[rel="apple-touch-icon"]');
    
    //     if (firstLinkElement) {
    //       // Ajouter les balises <meta> avant cette balise link
    //       head.insertBefore(metaTitle, firstLinkElement);
    //       head.insertBefore(metaDescription, firstLinkElement);
    //       head.insertBefore(metaImage, firstLinkElement);
    //       head.insertBefore(metaImageWidth, firstLinkElement);
    //       head.insertBefore(metaImageHeight, firstLinkElement);
    //     } else {
    //       // Si aucune balise link n'est trouvée, ajouter au début
    //       head.appendChild(metaTitle);
    //       head.appendChild(metaDescription);
    //       head.appendChild(metaImage);
    //       head.appendChild(metaImageWidth);
    //       head.appendChild(metaImageHeight);
    //     }
    
    //     // Nettoyage : retirer les éléments du head lorsqu'on démonte le composant
    //     return () => {
    //         head.removeChild(metaTitle);
    //         head.removeChild(metaDescription);
    //       head.removeChild(metaImage);
    //       head.removeChild(metaImageWidth);
    //       head.removeChild(metaImageHeight);
    //     };
    //   }, []);
    
    return (
        <>
        
        <Helmet>
            <title>{article.title}</title>
            <meta property="og:image" content={`${article.files && article.files?.length > 0 ? baseUrl + article.files[0].path : ''}`} />
            <meta property="og:image:width" content="1200"/>
            <meta property="og:image:height" content="800"/>
            <meta property="og:descrition" content="Test"/>
        </Helmet>

        <div>
             <div className="row mb-4">
                <div className="w-100 d-flex justify-content-between">
                    <div>
                        {/* <h3 className='d-none'><a href="#">HAODY MADAGASCAR</a></h3> */}
                    </div>
                   <div>
                   <Link style={{color: "#000", fontWeight:'bold', fontSize:'1.2rem'}} to={`${ClientRouter.home}`}>Trouver un logement de vacances</Link>
                   </div>
                </div>
            </div>
            {/* <div className="row">
                <div className="card" style={{width: "100%", height: "350px"}}>
                    <div className='articleHead' >
                        <img className='imgFond' src={fond} alt="" />
                    </div>
                </div>
            </div> */}
            <div className="row">
            <Swiper
                modules={[Navigation, Pagination, A11y]}
                spaceBetween={50}
                autoplay
                slidesPerView={1}
                style={{height: '400px', objectFit:'cover'}}
                navigation
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)}
                breakpoints={{
                    320: {
                      slidesPerView: 1,
                      spaceBetween: 50
                    },
        
                    640: {
                      slidesPerView: 2,
                      spaceBetween: 50
                    },
        
                    1024: {
                      slidesPerView: 3,
                      spaceBetween: 50
                    },
        
                    
                  }}
                >
                <SwiperSlide>
                    <img style={{objectFit:'cover', width:'200px', height: '400px'}}  src={im1} alt="" className="_fiara1" />
                </SwiperSlide>
                <SwiperSlide>
                    <img style={{objectFit:'cover', width:'200px', height: '400px'}} src={im2} alt="" className="_fiara1" />
                </SwiperSlide>
                <SwiperSlide>
                    <img style={{objectFit:'cover', width:'200px', height: '400px'}} src={im3} alt="" className="_fiara1" />
                </SwiperSlide>
                <SwiperSlide>
                    <img style={{objectFit:'cover', width:'200px', height: '400px'}} src={im4} alt="" className="_fiara1" />
                </SwiperSlide>
                <SwiperSlide>
                    <img style={{objectFit:'cover', width:'200px', height: '400px'}} src={im5} alt="" className="_fiara1" />
                </SwiperSlide>
            </Swiper>
                
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="mt-4">
                        
                        <div className="row">
                            
                            <div className="col-12 col-md-4"></div>
                            <div className="col-12 col-md-4">
                                {
                                    article.files && article.files.map((data: any, index: any) => (
                                        <div key={index}>
                                            <img style={{width: "100%", margin: 'auto'}} src={`${baseUrl}${data.path}`} alt={data.name} />
                                            {/* <meta property="og:image" content={baseUrl + data.path}/> */}
                                        </div>
                                    ))
                                }
                            </div>
                            <div className="col-12 col-md-4"></div>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <br />
            <div className="row">
                <div className="col-12 col-md-2"></div>
                <div className="col-12 col-md-8">
                    <div className="">
                        <div className="card-body">
                            <h2>{article.title}</h2>
                            {/* <meta name="og:description" content={article.title}/> */}
                            <p style={{textAlign:"justify"}}>
                                {parse(`${article.contentHtml}`)}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-2"></div>
            </div>
        </div>
        </>
    )
}
